<template>
    <div class="shortapp__container container">
        <div class="form-container">
            <div class="step first box-input" id="step1" v-show="step==1" @click="goNext(2)">
                <div class="headline" data-name="Purpose">
                    60 Second Quick Application
                </div>
                <div class="box refinance">
                    <img class="icon" src="assets/images/icon_stop.png" alt="stop">
                    <div class="btn-txt">Click Here</div>
                    <!-- <div class="txt" style="display: none;">Refinance</div> -->
                </div>
            </div>

            <div class="step box-input" id="step2" v-show="step==2">
                <div class="headline" data-name="Use">
                    Property Use?
                </div>
                <div class="box odd-display first-box" @click="choosePropertyUse(0)">
                    <img class="icon" src="assets/images/primaryresi.png" alt="primary">
                    <p class="txt small-mobile">Primary Residence</p>
                </div>
                <div class="box second-box" @click="choosePropertyUse(1)">
                    <img class="icon" src="assets/images/vacation.png" alt="vacation">
                    <p class="txt">Vacation Home</p>
                </div>
                <div class="box third-box" @click="choosePropertyUse(2)">
                    <img class="icon" src="assets/images/investment.png" alt="investment">
                    <p class="txt">Investment</p>
                </div>
            </div>

            <div class="step box-input" id="step3" v-show="step==3">
                <div class="headline" data-name="Property Type">
                    Property Type?
                </div>
                <div class="box odd-display" @click="choosePropertyType(0)">
                    <img class="icon" src="assets/images/singlehouse.png" alt="single">
                    <p class="txt">Single Family</p>
                </div>
                <div class="box" @click="choosePropertyType(1)">
                    <img class="icon" src="assets/images/Townhome.png" alt="townhome">
                    <p class="txt">Townhome</p>
                </div>
                <div class="box" @click="choosePropertyType(2)">
                    <img class="icon" src="assets/images/condo.png" alt="condo">
                    <p class="txt">Condo</p>
                </div>

            </div>

            <div class="step text-input r" id="step4" v-show="step==4">
                <div>
                    <div class="headline">Estimated Home Value?</div>
                    <span class="input-symbol-dollar  hidden-xs">
          <input class="estimated-home-value-amount-value dollar-input-text" type="text" name="Estimated_Home_Value"
                 v-model="estimatedHomeValue" aria-label="estimated value">
        </span>
                    <div class="visible-xs-block slider-value estimated-home-value-amount-value">
                        ${{estimatedHomeValue}}
                    </div>
                </div>
                <button name="input" type="button" class="sub-but fwd" @click="goNext(5)">Continue</button>

            </div>

            <div class="step text-input r" id="step5" v-show="step==5">
                <div>
                    <div class="headline">New Loan Amount?</div>
                    <span class="input-symbol-dollar hidden-xs">
          <input class="purchase-price-slider-value dollar-input-text" type="text" name="New_Loan_Amount"
                 v-model="newLoanAmount" aria-label="new loan amount">
        </span>

                </div>
                <button name="input" type="button" class="sub-but fwd" @click="goNext(6)">Continue</button>

            </div>


            <div class="step text-input" id="step7" v-show="step==6">
                <div class="headline">
                    Where Do you Currently live?
                </div>
                <div class="form-horizontal inline-form-horizontal">
                    <div class="form-group">
                        <label class="col-sm-4">Address:</label>
                        <!-- <input name="Street" type="text" class="textfield col-sm-8" placeholder="Street Address" aria-label="street address" v-model="location.address"> -->
                        <input ref="autocomplete"
                               placeholder="Street Address"
                               class="search-location textfield col-sm-8"
                               onfocus="value = ''"
                               type="text" aria-label="street address" v-model="location.address">
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4">City:</label>
                        <input name="City" type="text" class="textfield col-sm-8" id="city" placeholder="City"
                               aria-label="city" v-model="location.city">
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4">State:</label>
                        <input name="State" type="text" class="textfield col-sm-8" id="state" placeholder="State"
                               aria-label="state" v-model="location.state">
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4">Zip Code:</label>
                        <input name="Zip" type="number" class="textfield col-sm-8" id="zip" placeholder="Zip Code"
                               aria-label="zipcode" v-model="location.zipCode">
                    </div>
                </div>
                <button name="input" type="button" class="sub-but fwd" @click="goNext(7)">Continue</button>

            </div>

            <div class="step text-input" id="step8" v-show="step==7">
                <div class="headline">
                    Your Basic Information
                </div>
                <div class="form-horizontal inline-form-horizontal">
                    <div class="form-group">
                        <label class="col-sm-4">First Name:</label>
                        <input name="First_Name" type="text" class="textfield col-sm-8" placeholder="First Name"
                               required="required" aria-label="first name" v-model="profile.firstName">
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4">Last Name:</label>
                        <input name="Last_Name" type="text" class="textfield col-sm-8" placeholder="Last Name"
                               required="required" aria-label="last name" v-model="profile.lastName">
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4">Phone:</label>
                        <input name="Phone" type="number" class="textfield col-sm-8" placeholder="Phone"
                               required="required" aria-label="phone" v-model="profile.phone">
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4">Email:</label>
                        <input name="Email" type="email" class="textfield col-sm-8" placeholder="Email"
                               required="required" aria-label="email" v-model="profile.email">
                    </div>
                </div>
                <button name="input" type="button" class="sub-but fwd" @click="goNext(8)">Continue</button>

            </div>

            <div class="step multi-box-input employment" id="step9" data-name="Employment Status" v-show="step==8">
                <!-- <div class="headline">
                  Employment Status
                </div> -->
                <div class="multi-box-choice multi-box-first" data-value="Retired">
                    <div class="multi-box-title"><h3>{{employmentStatusArray[employmentStatusIterator]}}?</h3></div>
                    <div class="multi-box" data-value="Yes" @click="setEmploymentStatus">
                        <img class="icon" src="assets/images/yes.png" alt="yes">
                        <p class="txt">Yes</p>
                    </div>
                    <div class="multi-box" data-value="No" @click="increaseEmploymentStatusIterator">
                        <img class="icon" src="assets/images/no.png" alt="no">
                        <p class="txt">No</p>
                    </div>
                </div>

            </div>

            <div class="step text-input" id="step10" v-show="step==9">
                <div class="headline">
                    Social Security #:
                </div>
                <div>
                    <input name="SSN" type="number" class="textfield optional" maxlength="9"
                           placeholder="Social Security Number (optional)" aria-label="social security"
                           v-model="profile.socialSecurityNumber">
                    <br>
                </div>
                <button name="input" type="button" class="sub-but fwd" @click="goNext(10)">Continue</button>

            </div>

            <div class="step box-input coborrower" id="step11" v-show="step==10">
                <div class="headline" data-name="Co-Borrower">
                    Will there be a Co-Borrower?
                </div>
                <div class="box" id="cb_yes" @click="goNext(11)">
                    <img class="icon" src="assets/images/yes.png" alt="yes">
                    <p class="txt">Yes</p>
                </div>
                <div class="box" id="cb_no" @click="goNext(13)">
                    <img class="icon" src="assets/images/no.png" alt="no">
                    <p class="txt">No</p>
                </div>

            </div>

            <div class="step text-input c" id="step12" v-show="step==11">
                <div class="headline">
                    Co-Borrower Basic Information
                </div>
                <div class="form-horizontal inline-form-horizontal co-borrower-form">
                    <div class="form-group">
                        <label class="col-sm-4">First Name:</label>
                        <input name="Co-Borrower_First_Name" type="text" class="textfield col-sm-8"
                               placeholder="First Name" aria-label="first name" v-model="coProfile.firstName">
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4">Last Name:</label>
                        <input name="Co-Borrower_Last_Name" type="text" class="textfield col-sm-8"
                               placeholder="Last Name" aria-label="last name" v-model="coProfile.lastName">
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4">Phone:</label>
                        <input name="Co-Borrower_Phone" type="tel" class="textfield col-sm-8" placeholder="Phone"
                               aria-label="phone" v-model="coProfile.phone">
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4">Email:</label>
                        <input name="Co-Borrower_Email" type="email" class="textfield col-sm-8" placeholder="Email"
                               aria-label="email" v-model="coProfile.email">
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4">Social Security#:</label>
                        <input name="Co-Borrower_SSN" type="number" class="textfeild optional col-sm-10" maxlength="9"
                               placeholder="Social Security Number (optional)" aria-label="social security"
                               v-model="coProfile.socialSecurityNumber">
                    </div>
                </div>
                <button name="input" type="button" class="sub-but fwd" @click="goNext(12)">Continue</button>

            </div>

            <div class="step multi-box-input employment c" id="step13" data-name="Co-Borrower Employment Status"
                 v-show="step==12">
                <div class="headline">
                    Co-Borrower Employment Status
                </div>
                <div class="multi-box-choice multi-box-first" data-value="Retired">
                    <div class="multi-box-title">{{employmentStatusArray[coEmploymentStatusIterator]}}?</div>
                    <div class="multi-box" data-value="Yes" @click="setCoEmploymentStatus">
                        <img class="icon" src="assets/images/yes.png" alt="yes">
                        <p class="txt">Yes</p>
                    </div>
                    <div class="multi-box" data-value="No" @click="increaseCoEmploymentStatusIterator">
                        <img class="icon" src="assets/images/no.png" alt="no">
                        <p class="txt">No</p>
                    </div>
                </div>

            </div>

            <div class="step box-input" id="step14" v-show="step==13">
                <div class="headline" data-name="Loan Originator">
                    <h1><strong>Referred To:</strong></h1>
                </div>
                <div class="row" v-for="(row, i) in referrers" :key="i">
                    <div v-for="(person, index) in row" class="box lo" :key="index" @click="choosePerson(person)">
                        <div class="lo-image" :style="{ backgroundImage: person.image }"></div>
                        <div class="lo-btn-text">
                            <p class="txt">{{person.name}}</p>
                            <h6 style="padding-bottom: 5px">{{person.position}}</h6>
                        </div>
                        <p style="font-size: 8px">{{person.code}}</p>
                    </div>
                </div>

            </div>

            <div class="step text-input" id="step15" v-show="step==14">
                <div class="headline">
                    Referred By:
                </div>
                <div>
                    <input name="Referred_By" type="text" class="textfeild optional"
                           placeholder="Referred By (optional)" aria-label="referred" v-model="referredInfo">
                    <br>
                </div>
                <button name="input" type="button" class="sub-but fwd" @click="goNext(15)">Continue</button>

            </div>

            <div class="step box-input coborrower" id="step16" v-show="step==15">
                <div class="headline" data-name="Filed Tax Returns">
                    Have you filed your 2020 Tax Return?
                </div>
                <div class="box" id="cb_yes" @click="filedTax(true)">
                    <img class="icon" src="assets/images/yes.png" alt="yes">
                    <p class="txt">Yes</p>
                </div>
                <div class="box" id="cb_no" @click="filedTax(false)">
                    <img class="icon" src="assets/images/no.png" alt="no">
                    <p class="txt">No</p>
                </div>

            </div>

            <div class="step box-input coborrower" id="step17" v-show="step==16">
                <div class="headline" data-name="Shoresale - Foreclosure">
                    Have you ever experienced a shortsale or Foreclosure?
                </div>
                <div class="box" id="cb_yes" @click="experiencedSF(true)">
                    <img class="icon" src="assets/images/yes.png" alt="yes">
                    <p class="txt">Yes</p>
                </div>
                <div class="box" id="cb_no" @click="experiencedSF(false)">
                    <img class="icon" src="assets/images/no.png" alt="no">
                    <p class="txt">No</p>
                </div>

            </div>

            <div class="step" id="step18" v-show="step==17">
                <div class="headline">
                    You're one click away!
                </div>
                <div>
                    <button name="input" id="submit-form" type="button" class="sub-but fwd" @click="submit"
                            :disabled="searchDisabled">Submit
                    </button>
                </div>
                <p>By hitting the submit button you authorize Family
                    Mortgage to obtain a credit report from Experian Equifax
                    and Transunion. You further acknowledge that email is an
                    acceptable method of communication. All credit and
                    financial information is confidential.</p>

            </div>

            <div class="step last" id="step19" v-show="step==18">
                <div>
                    <h1>Thank you for submitting your information.<br><br>
                        We will contact you shortly</h1>
                </div>
            </div>

            <div class="arrow-container" v-show="arrowShow"><a class="arrow-btn back" aria-label="back"><i
                    class="fa fa-arrow-circle-left fa-2x" @click="goBefore"></i></a></div>
            <!-- <div class="arrow-container"><a href="#" class="arrow-btn back" aria-label="back"><i class="fa fa-arrow-circle-left fa-2x"></i></a> <a href="#" class="arrow-btn fwd" aria-label="next"><i class="fwd fa fa-arrow-circle-right fa-2x"></i></a></div> -->
        </div>
    </div>
</template>

<script>
    const axios = require('axios');
    export default {
        name: 'Content',
        data: () => ({
            step: 1,
            searchDisabled: false,
            propertyUse: 0,
            propertyType: 0,
            estimatedHomeValue: "250,000.00",
            newLoanAmount: "250,000.00",
            zipFinder: null,
            location: {
                address: "",
                city: "",
                state: "",
                zipCode: null
            },
            profile: {
                firstName: "",
                lastName: "",
                phone: null,
                email: null,
                socialSecurityNumber: null,
            },
            coProfile: {
                firstName: "",
                lastName: "",
                phone: null,
                email: null,
                socialSecurityNumber: null,
            },
            employmentStatusArray: ["Retired", "Self-Employed", "Employed By Others", "Not Working"],
            employmentStatusIterator: 0,
            employmentStatus: -1,
            coEmploymentStatusIterator: 0,
            coEmploymentStatus: -1,
            referrers: [
                [
                    {
                        name: "Evan",
                        image: "url(https://123easyloan.com/assets/images/Evan-head.jpg)",
                        position: "Originator",
                        code: "NMLS# 239065"
                    },
                    {
                        name: "Skip",
                        image: "url(https://123easyloan.com/assets/images/skip-head.jpg)",
                        position: "Manager",
                        code: "NMLS# 239078"
                    },
                    {
                        name: "Anne",
                        image: "url(https://123easyloan.com/assets/images/anne-head.jpg)",
                        position: "Originator",
                        code: "NMLS# 239092"
                    },
                ],
            ],
            referrer: {},
            referredInfo: "",
            taxReturn: false,
            experiencedOnTwo: false,
            gmApiUri: "https://maps.googleapis.com/maps/api/geocode/json?type=json&key=" + process.env.VUE_APP_GM_API_KEY + "&address="
        }),
        mounted() {
            this.autocomplete = new google.maps.places.Autocomplete(
                (this.$refs.autocomplete),
                {types: ['geocode']}
            );

            this.autocomplete.addListener('place_changed', () => {
                let place = this.autocomplete.getPlace();
                let address1 = "";
                let postcode = "";

                for (const component of place.address_components) {
                    const componentType = component.types[0];

                    console.log(component);

                    switch (componentType) {

                        case "street_number": {
                            address1 = `${component.long_name} ${address1}`;
                            break;
                        }

                        case "route": {
                            address1 += component.short_name;
                            break;
                        }

                        case "postal_code": {
                            postcode = `${component.long_name}${postcode}`;
                            break;
                        }

                        // case "postal_code_suffix": {
                        //   postcode = `${postcode}-${component.long_name}`;
                        //   break;
                        // }

                        case "locality":
                            this.location.city = component.long_name;
                            break;

                        case "administrative_area_level_1": {
                            this.location.state = component.short_name;
                            break;
                        }
                    }
                }

                this.location.address = address1;
                this.location.zipCode = postcode;

            });


        },
        computed: {
            arrowShow() {
                return this.step != 1 && this.step != 18
            }
        },
        methods: {
            goNext(step) {
                if ((step === 5 && this.estimatedHomeValue === "") || (step === 6 && this.newLoanAmount === "") || (step === 7 && (this.location.address === "" || this.location.city === "" || this.location.state === "" || (this.location.zipCode === null || this.location.zipCode == 0))) || (step === 8 && (this.profile.firstName === "" || this.profile.lastName === "" || this.profile.email === "" || (this.profile.phone === null || this.profile.phone == 0))) || (step === 12 && (this.coProfile.firstName === "" || this.coProfile.lastName === "" || this.coProfile.email === "" || (this.coProfile.phone === null || this.coProfile.phone == 0)))) {
                    alert("Please Complete Required Fields")
                    return
                }
                this.step = step
            },
            // goLocInfo() {
            //   if(this.zipFinder > 99999 || this.zipFinder < 10000) {
            //     alert("Zip Code should be 5 digits")
            //     return
            //   }
            //   var url = this.gmApiUri + this.zipFinder
            //   axios.get(url).then(res=>{
            //     var locArr = res.data.results[0].address_components;
            //     // if(locArr.length > 6){
            //     //   alert("Insert valid Zip Code please!")
            //     //   return
            //     // }
            //     // else{
            //     //   this.location.city = locArr[1].long_name
            //     //   if(locArr.length == 6) this.location.city = locArr[2].long_name
            //     //   var state = "", city = ""
            //     //   locArr.map(item=>{
            //     //     if(item.short_name && item.short_name.length==2 && item.short_name !="US")
            //     //       state = item.short_name
            //     //     if(item.types.includes("locality"))
            //     //       city = item.long_name
            //     //   })
            //     //   this.location.state = state
            //     //   this.location.zipCode = this.zipFinder
            //     //   this.goNext(7)
            //     // }

            //     var state = "", city = ""
            //     locArr.map(item=>{
            //       if(item.short_name && item.short_name.length==2 && item.short_name !="US")
            //         state = item.short_name
            //       if(item.types.includes("locality"))
            //         city = item.long_name
            //     })
            //     this.location.state = state
            //     this.location.city = city
            //     this.location.zipCode = this.zipFinder
            //     this.goNext(7)

            //   })

            // },
            choosePropertyUse(propertyUse) {
                this.propertyUse = propertyUse
                this.goNext(3)
            },
            choosePropertyType(propertyType) {
                this.propertyType = propertyType
                this.goNext(4)
            },
            setEmploymentStatus() {
                this.employmentStatus = this.employmentStatusIterator
                this.goNext(9)
            },
            increaseEmploymentStatusIterator() {
                this.employmentStatusIterator++
                if (this.employmentStatusIterator === 4) this.employmentStatusIterator = 0
            },
            setCoEmploymentStatus() {
                this.coEmploymentStatus = this.coEmploymentStatusIterator
                this.goNext(13)
            },
            increaseCoEmploymentStatusIterator() {
                this.coEmploymentStatusIterator++
                if (this.coEmploymentStatusIterator === 4) this.coEmploymentStatusIterator = 0
            },
            choosePerson(person) {
                this.referrer = person
                this.goNext(14)
            },
            filedTax(flag) {
                this.taxReturn = flag
                this.goNext(16)
            },
            experiencedSF(flag) {
                this.experiencedOnTwo = flag
                this.goNext(17)
            },
            goBefore() {
                this.step--
            },
            submit() {
                // var c = confirm("Are you really gonna submit?")
                var c = 1;
                if (c) {
                    this.searchDisabled = true;
                    const data = {
                        propertyUse: this.propertyUse,
                        propertyType: this.propertyType,
                        estimatedHomeValue: this.estimatedHomeValue,
                        newLoanAmount: this.newLoanAmount,
                        zipFinder: this.zipFinder,
                        location: this.location,
                        profile: this.profile,
                        coProfile: this.coProfile,
                        employmentStatus: this.employmentStatus,
                        coEmploymentStatus: this.coEmploymentStatus,
                        referrer: this.referrer,
                        referredInfo: this.referredInfo,
                        taxReturn: this.taxReturn,
                        experiencedOnTwo: this.experiencedOnTwo
                    }
                    axios.post(process.env.VUE_APP_API_URL + '/save', data).then(res => {
                        if (res.data && res.data.res == "success") {
                            // alert("Email sent")
                            // location.reload()
                            this.searchDisabled = false
                            this.step = 18
                        } else {
                            console.log(res)
                        }
                    })
                }
            }
        }
    }
</script>
