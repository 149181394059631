<template>
  <header class="header__main container">
    <div class="row">
      <div class="header__logo col-xs-6">
        <a href="/">
          <img src="/assets/images/familymortgage_07_1_1.png" alt="logo">
        </a>
      </div>
      <div class="header__phone-container col-xs-6">
        <div class="header__phone-inner-container lhp-edit" data-content-type="global-replace" data-edit-field="header-right">
          <div class="header__phone">
            <a href="tel:(561) 625-2660">(561) 625-2660</a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'ComponentHeader'
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
